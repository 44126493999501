@tailwind base;
@tailwind components;
@tailwind utilities;

/* h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lora', sans-serif !important;
    color: #41444e !important;
    font-weight: 400 !important;
    margin-top: 0;
    line-height: 1.2;
} */
.priceBookSelect option {
  background-color: red;
  padding-left: 50px;
}

.footer-content > ul > li > a {
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
}

.list-content > a {
  text-decoration: none !important;
}

.terms-menu > a {
  text-decoration: none !important;
  color: #6c737f !important;
}

.slider__item-button:hover {
  color: rgb(168, 171, 179) !important;
}

.c-tab__title {
  color: #41444e;
  font-family: Lora, sans-serif;
  font-weight: 400;
  font-size: 24px;
}

.css-1bumx6q:hover {
  cursor: pointer;
  color: red;
}

.css-1h58bwf:hover {
  text-decoration: none;
}

.css-1ygjngi:hover {
  text-decoration: none;
}

.title-max {
  color: #41444e;
  font-family: Lora, sans-serif;
  font-weight: 400;
}

.salesText > ul > li > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul > li > strong > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul > li > strong > a:hover {
  color: #7a7a84;
}

.salesText > p > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul {
  list-style: disc;
  margin: 0 0 40px 20px;
  padding: 0;
}

.salesText > p > a:hover {
  color: #7a7a84;
}

.salesText > ul > li > a:hover {
  color: #7a7a84;
}

.salesText > h3 > a:hover {
  color: #7a7a84;
}

.salesText > h3 > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul > li > ul {
  list-style: circle;
  margin-left: 20px;
}

.salesText > ul > li > ul > li > ul {
  list-style: circle;
  margin-left: 20px;
}

.salesText > ul > li > ul > li > ul > li > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul > li > ul > li > ul > li > a:hover {
  color: #7a7a84;
}

.salesText > ul > li > ul > li > a {
  text-decoration: underline;
  color: #272727;
}

.salesText > ul > li > ul > li > a:hover {
  color: #7a7a84;
}

.myBockMenu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -2rem 0;
}

.myBockMenu > li {
  /* flex: 1 0 20%;
    max-width: 20%; */
  margin: 0 100px 0 0;
  display: flex;
}

.myBockMenu > li > a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.myBockHeading {
  color: #41444e;
  font-family: Lora, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
}

.orderHistoryLink {
  color: #272727;
  text-decoration: none;
}

.orderHistoryLink:hover {
  color: #7a7a84;
  text-decoration: none;
}

/* .css-13cymwt-control {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    border-color: none !important;
}

.css-13cymwt-control:focus {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    border-color: none !important;
}

.css-1fdsijx-ValueContainer {
    padding: 0px 0px !important;
}

.css-1jqq78o-placeholder {
    margin: 0px;
}

.css-qbdosj-Input:focus {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    border-color: none !important;
} */
#blogSingleStyle table{
  border:none !important;
}
#blogSingleStyle p{
 text-align:left !important;
  margin-bottom:20px !important;
}
#blogSingleStyle img{
 text-align:left !important;
 height:100% !important;
 width:100% !important;
 object-fit:cover !important;
 margin-bottom:20px !important;
}
#blogSingleStyle table td{
 text-align:left !important;
}

.book-link {
  color: #41444e !important;
  text-decoration: none !important;
}